import React, {useMemo, useState, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {TObservablePage} from '../../types/commerce'
import {Box} from '../../vanilla'
import {Seo} from '../../components/seo'
import {LoginContainer} from '../../components/login-container'
import {Layout} from '../../components/layout'
import {usePageDataTracking} from '../../analytics/page-tracking'
import {useLocation} from 'react-router-dom'
import {CredentialScreens} from '../../types/ui'
import {LogInFormFields} from '../../types/forms/login'
import {CREDENTIAL_SCREENS} from '../../utils/constants/ui'
import {TwoFactorCode} from '../../components/two-factor-code-container'
import { Spinner } from '../../components/spinner'
import { Flex } from '../../vanilla'

const Login: TObservablePage = observer(() => {
  const sendPageData = usePageDataTracking();
  const pageType = 'login';
  const pageCategory = 'Login';
  const location = useLocation();
  const {redirectTo}: {redirectTo?: string} = location.state || {}

  const [currentCredScreen, setCurrentCredScreen] = useState<CredentialScreens>(
    CREDENTIAL_SCREENS.LOGIN,
  )
  const [currentCredentials, setCurrentCredentials] = useState<LogInFormFields>()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)


  useEffect(() => {
    const checkLoggedInStatus = (): Promise<boolean> => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(false)
        }, 1000)
      })
    }
    checkLoggedInStatus().then((loggedIn) => {
      setIsLoggedIn(loggedIn)
      setIsLoading(false)
    })
  }, [])

  const CredentialScreenSelector = useMemo(() => {
    if (isLoading) {
      return (
        <Flex justify="center" align="center" minHeight="96px" width="full">
          <Spinner color="primary" />
        </Flex>
      )
      
    }

    if (!isLoggedIn) {
      switch (currentCredScreen) {
        case CREDENTIAL_SCREENS.LOGIN:
          return (
            <LoginContainer
              searchParams={location.search}
              redirectTo={redirectTo}
              showTwoFactorScreenHandler={() => {
                setCurrentCredScreen(CREDENTIAL_SCREENS.TWO_FACTOR)
              }}
              setSuccessfulCredentials={setCurrentCredentials}
            />
          )
        case CREDENTIAL_SCREENS.TWO_FACTOR:
          return (
            <TwoFactorCode
              currentCredentials={currentCredentials}
              searchParams={location.search}
              redirectTo={redirectTo}
              setCredentialScreen={setCurrentCredScreen}
            />
          )
      }
    }
  }, [isLoading, isLoggedIn, currentCredScreen])

  sendPageData(pageType, pageCategory)
  return (
    <Layout>
      <Box bg="gray50" paddingY={['0px', '100px']}>
        <Seo title="Sign in" description="Customer sign in" />
        {CredentialScreenSelector}
      </Box>
    </Layout>
  )
})
Login.displayName = 'Login'

export default Login
